import { useTheme } from '@mui/material';
import { IconArrowDownLeft } from '@tabler/icons-react';
import { IconClockHour4 } from '@tabler/icons-react';
import { IconClockCancel, IconMailForward } from '@tabler/icons-react';
import { IconBallpen } from '@tabler/icons-react';
import React, { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import messages from 'components/InitiativeInfoSection/messages';
import {
  useAddInitiativesReactionMutation,
  useAddWatchedInitiativesMutation,
  useLazyJoinInitiativesRequestQuery,
  useLeaveInitiativeMutation,
  useRemoveWatchedInitiativesMutation,
  useUpdateInitiativesMutation,
  useUserMessageToOwnerMutation
} from 'store/rtk-query/initiativesApi';
import { INITIATIVE_TYPE, initiativeStates } from 'utils/constants/initiatives';
import { isAdministrator, isAuthorizedEntity, isInvitee, isJoinInitiativesPermitted, isMember, isOwner, isUserEligible } from 'utils/utils';

function useInitiativesInfoBox(initiativeInfo, userId, initiativeId, initiativeType, refetchInitiatives, isInWatchedList, initiativeState) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [leaveInitiatives] = useLeaveInitiativeMutation();
  const [joinInitiativesRequest] = useLazyJoinInitiativesRequestQuery();
  const [addWatchedInitiatives] = useAddWatchedInitiativesMutation();
  const [removeWatchedInitiatives] = useRemoveWatchedInitiativesMutation();
  const [updateInitiatives, { isLoading: isInitiativeUpdateLoading }] = useUpdateInitiativesMutation();
  const [userMessageToOwner, { isLoading: isUserMessageToOwnerLoading }] = useUserMessageToOwnerMutation();
  const [addInitiativesReaction] = useAddInitiativesReactionMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [archiveInitiativeDialogIsOpen, setArchiveInitiativeDialogIsOpen] = useState(false);
  const [contactAdminsDialogIsOpen, setContactAdminsDialogIsOpen] = useState(false);
  const [contactAdminMessage, setContactAdminMessage] = useState('');
  const open = Boolean(anchorEl);
  const archivedStates = initiativeStates.ARCHIVED === initiativeState;
  const draftStates = initiativeStates.DRAFTED === initiativeState;

  const iconSx = {
    color: palette.primary.mainLight,
    marginRight: '0.5rem',
    marginLeft: '0.5rem'
  };

  const visibleForAdministrators = useMemo(() => {
    if (initiativeInfo && userId) {
      return isAdministrator(initiativeInfo, userId);
    }
  }, [initiativeInfo, userId]);

  const isAdminOrMemberIsNotOwner = useMemo(() => {
    if (initiativeInfo && userId) {
      return (isAdministrator(initiativeInfo, userId) || isMember(initiativeInfo, userId)) && !isOwner(initiativeInfo, userId);
    }
  }, [initiativeInfo, userId]);

  const isVisibleJoinInitiativesButton = useMemo(() => {
    if (initiativeInfo && userId) {
      return isJoinInitiativesPermitted(initiativeInfo, userId);
    }
  }, [initiativeInfo, userId]);

  const shouldReactionShow = useMemo(() => {
    if (initiativeInfo) {
      return isUserEligible(initiativeInfo);
    }
  }, [initiativeInfo]);

  const currentCompleteUrl = window.location.href.endsWith('/')
    ? `${window.location.href}${initiativeId}`
    : `${window.location.href}/${initiativeId}`;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenArchiveInitiativeAlertDialog = () => {
    setArchiveInitiativeDialogIsOpen(true);
  };

  const handleJoinInitiatives = async () => {
    const response = await joinInitiativesRequest(initiativeId);
    if (response?.data) {
      toast.success(response?.data?.data?.data || 'You have successfully joined the initiative!');
    } else {
      toast.error(response?.error?.data?.data?.error || "This initiative privacy is not public and you can't join in");
    }
    handleClose();
  };

  const handleWatchedInitiatives = async () => {
    let response;
    if (!isInWatchedList) {
      response = await addWatchedInitiatives(initiativeId);
    } else {
      response = await removeWatchedInitiatives({
        initiativeId,
        removeFromList: initiativeType === INITIATIVE_TYPE.WATCHED ? true : false
      });
    }
    if (response?.data) {
      toast.success(response?.data?.data?.data || 'You have successfully added this initiative in your watch list!');
    } else {
      toast.error(response?.error?.data?.data?.error || 'This initiative is already in your watch list.');
    }
    handleClose();
  };

  const handleCopyURL = useCallback(() => {
    try {
      navigator.clipboard.writeText(currentCompleteUrl);
      toast.success(`${intl.formatMessage(messages.CopiedInitiativeURL)}`);
    } catch (error) {
      toast.error(`${intl.formatMessage(messages.FailedCopiedInitiativeURL)}` || error);
    }
  }, [currentCompleteUrl, intl]);

  const handleSubmit = async () => {
    const response = await updateInitiatives({
      body: { initiativeState: archivedStates || draftStates ? initiativeStates.PUBLISHED : initiativeStates.ARCHIVED },
      userId,
      initiativeId
    });
    if (response?.data?.success) {
      toast.success(archivedStates || draftStates ? 'Initiative published successfully !' : 'Initiative archived successfully !');
      setArchiveInitiativeDialogIsOpen(false);
      handleClose();
    } else {
      toast.error('Failed to archive/delete initiative!');
      setArchiveInitiativeDialogIsOpen(false);
      handleClose();
    }
  };

  const handleContactAdminsTextSubmit = async () => {
    await userMessageToOwner({ body: contactAdminMessage, initiativeId });
    setContactAdminsDialogIsOpen(false);
    setContactAdminMessage('');
  };

  const handleActionButton = async (reaction_Type) => {
    await addInitiativesReaction({
      body: {
        type: reaction_Type,
        user: {
          uuid: userId
        }
      },
      initiativeId
    });
  };

  const handleNavigateToInitiative = (showLink) => {
    if (showLink) return;
    navigate(`/initiatives/${initiativeId}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseArchiveInitiativeAlertDialog = () => {
    setArchiveInitiativeDialogIsOpen(false);
  };

  const options = [
    visibleForAdministrators &&
      !archivedStates && {
        icon: <IconBallpen style={iconSx} />,
        id: 1,
        title: 'Edit Initiative',
        onClick: (event) => {
          event.stopPropagation();
          handleClose();
          navigate(`/initiatives/edit/${event?.target?.slot}`);
        }
      },
    isVisibleJoinInitiativesButton && {
      icon: <IconBallpen style={iconSx} />,
      id: 2,
      title: 'Join Initiative',
      onClick: (event) => {
        event.stopPropagation();
        handleJoinInitiatives();
      }
    },
    !archivedStates &&
      !draftStates &&
      visibleForAdministrators && {
        icon: <IconClockCancel style={iconSx} />,
        id: 3,
        title: 'Archive',
        onClick: () => {
          handleOpenArchiveInitiativeAlertDialog();
        }
      },
    (archivedStates || draftStates) &&
      visibleForAdministrators && {
        icon: <IconClockCancel style={iconSx} />,
        id: 4,
        title: 'Publish',
        onClick: () => {
          handleOpenArchiveInitiativeAlertDialog();
        }
      },
    !isAuthorizedEntity(initiativeInfo, userId) &&
      !isAdministrator(initiativeInfo, userId) &&
      !isInvitee(initiativeInfo, userId) &&
      !isMember(initiativeInfo, userId) && {
        icon: <IconMailForward style={iconSx} />,
        id: 5,
        title: intl.formatMessage(messages.ContactInitiativeAdmin),
        onClick: () => setContactAdminsDialogIsOpen(true)
      },
    {
      icon: <IconClockHour4 style={iconSx} />,
      id: 6,
      title: !isInWatchedList ? intl.formatMessage(messages.AddToWatchList) : intl.formatMessage(messages.RemoveFromWatchList),
      onClick: (event) => {
        event.stopPropagation();
        handleWatchedInitiatives();
      }
    },
    isAdminOrMemberIsNotOwner && {
      icon: <IconArrowDownLeft style={iconSx} />,
      id: 7,
      title: intl.formatMessage(messages.LeaveInitiative),
      onClick: async () => {
        try {
          const result = await leaveInitiatives(initiativeId);
          if (result.error) {
            toast.error(result?.error?.data?.data?.error || 'Failed to leave initiative!');
            return;
          }
          toast.success(result?.data?.data?.data || 'Initiative left successfully !');
          refetchInitiatives();
        } catch (errorInitiativeLeave) {
          toast.error(errorInitiativeLeave?.error?.data?.data?.error || 'Failed to leave initiative!');
        } finally {
          handleClose();
        }
      }
    }
  ].filter(Boolean);

  return {
    options,
    handleClose,
    intl,
    palette,
    handleCopyURL,
    handleSubmit,
    handleContactAdminsTextSubmit,
    handleActionButton,
    handleNavigateToInitiative,
    handleClick,
    handleCloseArchiveInitiativeAlertDialog,
    isInitiativeUpdateLoading,
    isUserMessageToOwnerLoading,
    archiveInitiativeDialogIsOpen,
    contactAdminsDialogIsOpen,
    shouldReactionShow,
    open,
    archivedStates,
    draftStates,
    anchorEl,
    setContactAdminMessage,
    setContactAdminsDialogIsOpen,
    contactAdminMessage
  };
}

export default useInitiativesInfoBox;
