import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { RtkBaseQuery } from 'utils/request';
import queryTags from 'utils/constants/queryTags';
import queryEndPoints from 'utils/queryEndPoints';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.getAllUsersNotifications],
  endpoints: (builder) => ({
    getAllUserNotifications: builder.query({
      query: ({ offset, limit }) => queryEndPoints.getAllUserNotifications({ offset, limit }),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset === 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
        } else {
          currentCache.resultList = [...currentCache.resultList, ...newItems.resultList];
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg.limit !== previousArg?.limit || currentArg.offset !== previousArg?.offset;
      },
      providesTags: [queryTags.getAllUsersNotifications]
    }),
    getNewNotificationCount: builder.query({
      query: () => queryEndPoints.getNewNotificationCount(),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.getAllUsersNotifications]
    }),
    markedAsReadNotification: builder.query({
      query: () => queryEndPoints.markedAsReadNotification(),
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchGetNewNotificationCountResult = dispatch(
          notificationsApi.util.updateQueryData('getNewNotificationCount', undefined, () => 0)
        );

        const patchGetAllUserNotificationsResult = dispatch(
          notificationsApi.util.updateQueryData('getAllUserNotifications', undefined, (draft) => {
            draft.resultList.forEach((notification) => {
              notification.status = 'READ';
            });
          })
        );

        try {
          await queryFulfilled;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          patchGetNewNotificationCountResult.undo();
          patchGetAllUserNotificationsResult.undo();
        }
      }
    }),

    addNotification: builder.mutation({
      query: ({ body, sendMailToUsers }) => {
        return {
          url: queryEndPoints.addNotification({ sendMailToUsers }),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            notificationsApi.util.updateQueryData('getAllUserNotifications', undefined, (draft) => {
              const newNotification = {
                uuid: data.data.data.uuid,
                title: data.data.data.title,
                type: data.data.data.type,
                content: data.data.data.content,
                createdAt: data.data.data.createdAt,
                modifiedAt: data.data.data.modifiedAt,
                status: 'UNREAD'
              };
              draft.resultList.unshift(newNotification);
              draft.totalCount += 1;
            })
          );

          dispatch(
            notificationsApi.util.updateQueryData('getNewNotificationCount', undefined, (draft) => {
              draft += 1;
              return draft;
            })
          );

          toast.success('Notification added successfully!');
        } catch (error) {
          toast.error('Failed to add notification!');
          console.error('Failed to add notification:', error);
        }
      }
    }),
    updateNotification: builder.mutation({
      query: ({ notificationId, body }) => {
        return {
          url: queryEndPoints.updateNotification({ notificationId }),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      invalidatesTags: [queryTags.getAllUsersNotifications]
    }),
    deleteNotification: builder.mutation({
      query: ({ notificationId }) => ({
        url: queryEndPoints.deleteNotification({ notificationId }),
        method: API_REQUEST_METHOD.DELETE
      }),
      async onQueryStarted({ notificationId }, { dispatch, queryFulfilled }) {
        const patchGetAllNotificationsResult = dispatch(
          notificationsApi.util.updateQueryData('getAllUserNotifications', undefined, (draft) => {
            const notificationIndex = draft.resultList.findIndex((item) => item.uuid === notificationId);
            const isUnread = draft.resultList[notificationIndex]?.status === 'UNREAD';

            draft.resultList.splice(notificationIndex, 1);
            draft.totalCount -= 1;

            if (isUnread) {
              dispatch(
                notificationsApi.util.updateQueryData('getNewNotificationCount', undefined, (unreadDraft) => {
                  if (unreadDraft > 0) unreadDraft -= 1;
                  return unreadDraft;
                })
              );
            }
          })
        );

        try {
          await queryFulfilled;
          toast.success('Notification deleted successfully!');
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          toast.error('Failed to delete notification!');
          patchGetAllNotificationsResult.undo();
        }
      }
    }),

    singleNotificationMarkAsRead: builder.query({
      query: ({ notificationId }) => queryEndPoints.singleNotificationMarkAsRead({ notificationId }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [queryTags.getAllUsersNotifications],
      onQueryStarted: async ({ notificationId }, { dispatch, queryFulfilled }) => {
        const patchGetAllNotificationsResult = dispatch(
          notificationsApi.util.updateQueryData('getAllUserNotifications', undefined, (draft) => {
            const notification = draft.resultList.find((item) => item.uuid === notificationId);
            if (notification) {
              notification.status = 'READ';
            }
          })
        );

        const patchGetNewNotificationCountResult = dispatch(
          notificationsApi.util.updateQueryData('getNewNotificationCount', undefined, (draft) => {
            draft -= 1;
            return draft;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchGetAllNotificationsResult.undo();
          patchGetNewNotificationCountResult.undo();
        }
      }
    }),
    getNotificationById: builder.query({
      query: ({ notificationId }) => queryEndPoints.getSingleNotifications({ notificationId }),
      transformResponse: (response) => response?.data?.data
    })
  })
});

export const {
  useGetAllUserNotificationsQuery,
  useLazyGetAllUserNotificationsQuery,
  useAddNotificationMutation,
  useUpdateNotificationMutation,
  useLazySingleNotificationMarkAsReadQuery,
  useDeleteNotificationMutation,
  useGetNewNotificationCountQuery,
  useLazyMarkedAsReadNotificationQuery,
  useGetNotificationByIdQuery,
  useLazyGetNotificationByIdQuery
} = notificationsApi;
