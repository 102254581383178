import { createApi } from '@reduxjs/toolkit/query/react';
import { RtkBaseQuery } from 'utils/request';
import queryTags from 'utils/constants/queryTags';
import queryEndPoints from 'utils/queryEndPoints';

export const networkApi = createApi({
  reducerPath: 'networkApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.networkList, queryTags.networkDetailsByUser, queryTags.allInitiativesByUser, queryTags.networkActivityLogs],
  endpoints: (builder) => ({
    getNetworkList: builder.query({
      query: ({ networkType, initiativeId, offset, limit, filterKeywords, searchReactor, relatedToMe, filterUserIds }) =>
        queryEndPoints.getNetworkListByUserId({
          networkType,
          initiativeId,
          offset,
          limit,
          filterKeywords,
          searchReactor,
          relatedToMe,
          filterUserIds
        }),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondition =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg.networkType !== previousArg?.networkType ||
          currentArg.filterKeywords !== previousArg?.filterKeywords ||
          currentArg.searchReactor !== previousArg?.searchReactor ||
          currentArg.relatedToMe !== previousArg?.relatedToMe ||
          currentArg?.initiativeId !== previousArg?.initiativeId;
        return checkRefetchCondition;
      },
      providesTags: [queryTags.networkList]
    }),
    getNetworkDetailsByUserId: builder.query({
      query: ({ networkUserId, networkType }) => queryEndPoints.getNetworkDetailsByUserId(networkUserId, networkType),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.networkDetailsByUser]
    }),
    networkTabsCount: builder.query({
      query: ({ networkId, networkType }) => queryEndPoints.getAllNetworksTabsCount(networkId, networkType),
      transformResponse: (response) => response?.data
    }),
    // network activity logs
    getNetworkActivityLogs: builder.query({
      query: ({ networkId, offset, limit, networkType }) =>
        queryEndPoints.getNetworkActivityLogs({ networkId, offset, limit, networkType }),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName, // caching key override here
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondition =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg?.userId !== previousArg?.userId ||
          currentArg?.networkType !== previousArg?.networkType;
        return checkRefetchCondition;
      },
      providesTags: [queryTags.networkActivityLogs]
    })
  })
});

export const {
  useGetNetworkListQuery,
  useLazyGetNetworkListQuery,
  useGetNetworkDetailsByUserIdQuery,
  useLazyGetNetworkDetailsByUserIdQuery,
  useNetworkTabsCountQuery,
  useGetNetworkActivityLogsQuery
} = networkApi;
