import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import ProtectedRoute from 'components/ProtectedRoute';
import { IconMoodSad } from '@tabler/icons-react';
import { useIntl } from 'react-intl';
import Loader from 'components/Progress/Loader';
import Loadable from 'components/@common/Loadable';
import loginMessages from '../pages/Auth/Login/messages';
import paths from 'utils/constants/paths';
import { LoginFields, setPasswordFields } from 'utils/constants/loginConstants';
import { colors } from 'utils/constants/colors';
import messages from 'components/Accessiblity/messages';
import useLocalStorage from 'hooks/useLocalStorage';
import globalConstant from 'utils/constants/globalConstant';
import MainLayout from 'layout';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';

// ==============================|| ROUTING RENDER ||============================== //

// Auth Routes
const LoginPage = Loadable(lazy(() => import('pages/Auth/Login')));
const SetPasswordPage = Loadable(lazy(() => import('pages/Auth/Login')));
const SendEmailPage = Loadable(lazy(() => import('pages/Auth/ForgetPassword/SendEmail')));
const SetNewPasswordPage = Loadable(lazy(() => import('pages/Auth/ForgetPassword/SetNewPassword')));

// Main Routes
const MyInnofusePage = Loadable(lazy(() => import('pages/MyInnofuse')));
const ProfilePage = Loadable(lazy(() => import('pages/Profile')));
const InitiativesHubPage = Loadable(lazy(() => import('pages/InitiativeHub')));
const InitiativesDetailPage = Loadable(lazy(() => import('pages/InitiativeHub/InitiativeOverView')));
const ContributionsDetailPage = Loadable(lazy(() => import('pages/Contributions')));
const LinkedInitiativeDetailPage = Loadable(lazy(() => import('pages/InitiativeHub/LinkedInitiative')));
const AddContributionsPage = Loadable(lazy(() => import('pages/Contributions/Add')));
const EditContributionsPage = Loadable(lazy(() => import('pages/Contributions/Add')));
const AddInitiativesPage = Loadable(lazy(() => import('pages/InitiativeHub/Add')));
const SelectInitiativePage = Loadable(lazy(() => import('pages/InitiativeHub/Select')));
const InvitesPage = Loadable(lazy(() => import('pages/InitiativeHub/Invites')));
const AdminInvitesPage = Loadable(lazy(() => import('pages/InitiativeHub/Invites/adminInvites')));
const JoinInitiativesPage = Loadable(lazy(() => import('pages/InitiativeHub/JoinInitiatives')));
const RegisterPage = Loadable(lazy(() => import('pages/Auth/Register')));
const SearchReactorPage = Loadable(lazy(() => import('pages/Reactor')));
const NetworkDetailPage = Loadable(lazy(() => import('pages/NetworkHub/NetworkDetails')));
const NetworkHub = Loadable(lazy(() => import('pages/NetworkHub')));
const ChatPage = Loadable(lazy(() => import('pages/Chat')));
const ContactUsPage = Loadable(lazy(() => import('pages/ContactUs')));
const NotificationManagementPage = Loadable(lazy(() => import('pages/NotificationManagement')));

// Admin  Routes
const LicenseAdministrationPage = Loadable(lazy(() => import('pages/LicenseAdministration')));

//  Super Admin Routes
const SuperAdminPage = Loadable(lazy(() => import('pages/SuperAdmin/index.jsx')));
// Not found page
const NotFoundPage = Loadable(lazy(() => import('components/Accessiblity/InsightBox')));

export default function AppRouter() {
  const intl = useIntl();
  const [jwtTokenValue] = useLocalStorage(globalConstant.JWT_TOKEN, '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: userData } = useGetUserInfoQuery(undefined, { skip: !jwtTokenValue });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { data: tourStates } = useGetAllTourStatesQuery(undefined, { skip: !jwtTokenValue });

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Not logged In Route */}
        <Route path={paths.Login.url} element={<LoginPage fields={LoginFields} showCheckbox />} />
        <Route
          path={paths.SetPassword.url}
          element={<SetPasswordPage fields={setPasswordFields} heading={loginMessages.setPasswordButtonText} />}
        />
        <Route path={paths.SendEmail.url} element={<SendEmailPage />} />
        <Route path={paths.SetPassword.SetNewPassWord.url} element={<SetNewPasswordPage />} />
        <Route path={paths.OrganizationUserRegistration.url} element={<RegisterPage />} />

        {/* Logged In User Routes - with roles */}
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route path={paths.Root.url} element={<MyInnofusePage />} />
          <Route path={paths.Profile.url} element={<ProfilePage />} />
          <Route path={paths.Initiatives.url} element={<InitiativesHubPage />} />
          <Route path={paths.InitiativesTabs.url} element={<InitiativesDetailPage />} />
          <Route path={paths.AddInitiatives.url} element={<AddInitiativesPage />} />
          <Route path={paths.EditInitiatives.url} element={<AddInitiativesPage />} />
          <Route path={paths.SelectInitiative.url} element={<SelectInitiativePage />} />
          <Route path={paths.InitiativesDetail.url} element={<InitiativesDetailPage />} />
          <Route path={paths.ContactUs.url} element={<ContactUsPage />} />
          <Route path={paths.InitiativesDetail.ContributionsDetail.url} element={<ContributionsDetailPage />} />
          <Route path={paths.InitiativesDetail.LinkedInitiativeDetail.url} element={<LinkedInitiativeDetailPage />} />
          <Route path={paths.InitiativesDetail.AddContributions.url} element={<AddContributionsPage />} />
          <Route path={paths.InitiativesDetail.EditContributions.url} element={<EditContributionsPage />} />
          <Route path={paths.InitiativesDetail.Invites.url} element={<InvitesPage />} />
          <Route path={paths.InitiativesDetail.AdminInvites.url} element={<AdminInvitesPage />} />
          <Route path={paths.InitiativesDetail.JoinInitiatives.url} element={<JoinInitiativesPage />} />
          <Route path={paths.Reactor.url} element={<SearchReactorPage />} />
          <Route path={paths.Network.url} element={<NetworkHub />} />
          <Route path={paths.NetworkDetails.url} element={<NetworkDetailPage />} />
          <Route path={paths.NetworkTabs.url} element={<NetworkDetailPage />} />
          {/* Admin Routes */}
          <Route path={paths.LicenseAdministration.url} element={<LicenseAdministrationPage />} />
          {/* Super Admin Routes */}
          <Route path={paths.SuperAdminWithTab.url} element={<SuperAdminPage />} />
          <Route path={paths.SuperAdmin.url} element={<SuperAdminPage />} />
        </Route>

        {/* Special route for collaboration */}
        <Route
          element={
            <ProtectedRoute>
              <MainLayout withOutContainer />
            </ProtectedRoute>
          }
        >
          <Route path={paths.Chats.url} element={<ChatPage />} />
          <Route path={paths.Chats.UserChats.url} element={<ChatPage />} />
          <Route path={paths.NotificationsUrl.url} element={<NotificationManagementPage />} />
        </Route>

        {/* Invalid Route */}
        <Route
          path="*"
          element={
            <NotFoundPage
              title="404 !"
              renderElement={<IconMoodSad size={400} style={{ color: colors.default }} />}
              description={intl.formatMessage(messages.PageNotFoundDescription)}
            />
          }
        />
      </Routes>
    </Suspense>
  );
}
