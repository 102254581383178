const globalConstant = {
  JWT_TOKEN: 'JwtToken',
  DEFAULT_PAGINATION_LIMIT: 10,
  DEFAULT_PAGINATION_LIMIT_FOR_NETWORKING: 9,
  DEFAULT_PAGE_OFFSET: 0,
  DEFAULT_MAX_RETRIES_CONTROLLER_LEVEL: 1,
  DEFAULT_MAX_RETRIES_ROUTE_LEVEL: 1,
  CONFIG: 'configuration',
  DEFAULT_INITIATIVES_LIST_LIMIT: 5,
  MAX_FILE_SIZE_MB: 0.2, // 200 kb
  IMAGE_PREFIX: '/app/imageData',
  DEFAULT_MAX_KEYWORDS_LIMIT: 20,
  DEFAULT_ACTIVITIES_LIST_LIMIT: 20,
  CHAT_MESSAGES_LIMIT: 15,
  MY_INNOFUSE_MATCHED_NETWORK_LIMIT: 2,
  SUPPORT_MAIL_INNOFUSE: 'support@innofuse.swiss',
  SLOT_URL: 'info@innofuse.swiss',
  FEEDBACK_FORM_LINK:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAAXon2LBUMUg4NEpWM0pGUVNZNlZYWTlPRzRXSUdSWC4u',
  COMMUNITY: 'Community',
  CAMPAIGN: 'Campaign',
  EVENT: 'Event',
  DEFAULT_LIMIT_RECOMMENDED_USERS: 3,
  DEFAULT_LIMIT_RECOMMENDED_ORGS: 3
};

export const goToMenuArray = [5, 10, 25, 50, 100];

export const API_REQUEST_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

export const VERSION = {
  V1: 'v1',
  V2: 'v2'
};

export const RESPONSE = {
  ERROR: {
    UNAUTHORIZED: 'UNAUTHORIZED',
    FORBIDDEN: 'FORBIDDEN'
  },
  SUCCESS: {}
};

export const searchCategory = {
  NETWORK: 'network',
  INITIATIVES: 'initiative',
  ENTERPRISES: 'enterprise'
};

export const networkTypeObj = {
  ENTERPRISES: 'enterprises',
  PERSONS: 'persons'
};

export const chatMessageStatusObj = {
  DELIVERED: 'DELIVERED',
  RECEIVED: 'RECEIVED'
};

export const superAdminTabValues = {
  USER_MANAGEMENT: 'user-management',
  ENTERPRISE_MANAGEMENT: 'enterprise-management',
  NOTIFICATION_MANAGEMENT: 'notification-management'
};

export const notificationType = {
  ADMINISTRATOR: 'System',
  USER: 'User'
};

export const notificationStatus = {
  READ: 'READ',
  UNREAD: 'UNREAD'
};

export const PROJECTEXPERIENCE = 'projectExperiences';
export const OTHEREXPERIENCE = 'otherExperiences';

export const sendMessagePrefix = '/app/v1/chat';

export default globalConstant;
