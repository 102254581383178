/* eslint-disable import/no-cycle */
import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import Papa from 'papaparse';
import { RtkBaseQuery, customBackOff } from '../../utils/request';
import { usersListApi } from './usersListApi';
import { organizationApi } from './organizationApi';
import queryTags from 'utils/constants/queryTags';
import globalConstant, { API_REQUEST_METHOD, RESPONSE } from 'utils/constants/globalConstant';
import queryEndPoints from 'utils/queryEndPoints';

export const userAPI = createApi({
  reducerPath: 'userApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.userInfo, queryTags.getAllProjectExperienceByUser, queryTags.getProfileVisibilityStatus],
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => queryEndPoints.getUserInfoByTokenV1,
      providesTags: [queryTags.userInfo],
      transformResponse: (response) => response?.data?.data,
      keepUnusedDataFor: 2 * 60, // 2 minutes cache time
      extraOptions: {
        maxRetries: globalConstant.DEFAULT_MAX_RETRIES_ROUTE_LEVEL,
        backoff: () => customBackOff(),
        retryCondition: ({ data }) => {
          const isUserUnauthorized = data?.statusCode === RESPONSE.ERROR.UNAUTHORIZED;
          const isTokenExist = localStorage.getItem(globalConstant.JWT_TOKEN);
          const isUserUnauthorizedOrTokenNotExist = isUserUnauthorized || !isTokenExist;
          if (isUserUnauthorizedOrTokenNotExist) {
            if (isTokenExist) {
              toast.error('Something went wrong! Please login again.', { duration: 3000 });
            }
            localStorage.removeItem(globalConstant.JWT_TOKEN);
          }
          return isUserUnauthorizedOrTokenNotExist ? false : true;
        }
      }
    }),
    getUserInfoById: builder.query({
      query: (uuid) => queryEndPoints.getUserByUUID(uuid),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.userInfo]
    }),
    updateUser: builder.mutation({
      query: ({ uuid, body }) => {
        return {
          url: queryEndPoints.updateUserById(uuid),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUserInfo } = await queryFulfilled;
          const userInfoToBeUpdated = updatedUserInfo?.data?.data;
          await dispatch(userAPI.util.upsertQueryData('getUserInfo', undefined, userInfoToBeUpdated));
          toast.success('User updated successfully !');
        } catch (error) {
          toast.error(error?.message || 'Failed to update user');
        }
      }
    }),
    updateUserDetails: builder.mutation({
      query: ({ uuid, body }) => {
        return {
          url: queryEndPoints.updateUserById(uuid),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUserInfo } = await queryFulfilled;
          const userInfoToBeUpdated = updatedUserInfo?.data?.data;
          dispatch(
            usersListApi.util.updateQueryData('usersPaymentInformationList', undefined, (prevResult) => {
              const currentUserListData = prevResult?.resultList;
              const initiativeIndex = currentUserListData.findIndex((item) => item.uuid === userInfoToBeUpdated?.uuid);
              if (initiativeIndex !== -1) {
                currentUserListData[initiativeIndex] = { ...currentUserListData[initiativeIndex], ...userInfoToBeUpdated };
              } else {
                currentUserListData.unshift(userInfoToBeUpdated);
              }
            })
          );
          dispatch(
            organizationApi.util.updateQueryData('getAllOrganizationUsers', undefined, (prevResult) => {
              const currentUserListData = prevResult?.resultList;
              const initiativeIndex = currentUserListData.findIndex((item) => item.uuid === userInfoToBeUpdated?.uuid);
              if (initiativeIndex !== -1) {
                currentUserListData[initiativeIndex] = { ...currentUserListData[initiativeIndex], ...userInfoToBeUpdated };
              } else {
                currentUserListData.unshift(userInfoToBeUpdated);
              }
            })
          );
          toast.success('User updated successfully !');
        } catch (error) {
          toast.error(error?.message || 'Failed to update user');
        }
      }
    }),
    uploadUserProfileImage: builder.mutation({
      query: ({ userId, file }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        return {
          url: queryEndPoints.uploadUserProfileImage(userId),
          method: API_REQUEST_METHOD.POST,
          body: bodyFormData
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: updatedUserInfo } = await queryFulfilled;
        const userInfoToBeUpdated = updatedUserInfo?.data?.data;
        await dispatch(userAPI.util.upsertQueryData('getUserInfo', undefined, userInfoToBeUpdated));
      }
    }),
    updateUserRole: builder.mutation({
      query: ({ userId, body }) => {
        return {
          url: queryEndPoints.updateUserRole(userId),
          method: API_REQUEST_METHOD.PUT,
          body: body
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUserInfo } = await queryFulfilled;
          const userInfoToBeUpdated = updatedUserInfo?.data?.data;
          await dispatch(
            usersListApi.util.updateQueryData('usersPaymentInformationList', undefined, (prev) => {
              const indexToBeUpdated = prev?.resultList?.findIndex((item) => item?.uuid === userInfoToBeUpdated?.uuid);
              if (indexToBeUpdated !== -1) {
                prev.resultList[indexToBeUpdated] = userInfoToBeUpdated;
              }
            })
          );
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          /* empty */
        }
      }
    }),
    getAllProjectExperienceByUser: builder.query({
      query: () => queryEndPoints.getAllProjectExperienceByUser,
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.getAllProjectExperienceByUser]
    }),
    addProjectExperience: builder.mutation({
      query: ({ body }) => {
        return {
          url: queryEndPoints.addProjectExperience,
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      invalidatesTags: [queryTags.getAllProjectExperienceByUser]
    }),
    updateProjectExperience: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: queryEndPoints.updateProjectExperience(id),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      invalidatesTags: [queryTags.getAllProjectExperienceByUser]
    }),
    deleteProjectExperience: builder.mutation({
      query: (id) => {
        return {
          url: queryEndPoints.deleteProjectExperience(id),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      invalidatesTags: [queryTags.getAllProjectExperienceByUser]
    }),
    profileVisibility: builder.mutation({
      query: ({ body }) => {
        return {
          url: queryEndPoints.profileVisibility,
          method: API_REQUEST_METHOD.PUT,
          body
        };
      },
      invalidatesTags: [queryTags.getProfileVisibilityStatus]
    }),
    getProfileVisibilityStatus: builder.query({
      query: () => queryEndPoints.profileVisibility,
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.getProfileVisibilityStatus]
    }),
    checkUserOwnerShipInInitiative: builder.query({
      query: (userId) => queryEndPoints.checkUserOwnerShipInInitiative(userId),
      transformResponse: (response) => response?.data?.data
    }),
    exportedUser: builder.query({
      query: () => ({
        url: queryEndPoints.exportedUser(),
        responseHandler: async (response) => {
          const text = await response.text();
          return text;
        }
      }),
      transformResponse: (response) => {
        try {
          const parsedData = Papa.parse(response, {
            header: true,
            skipEmptyLines: true
          });
          return parsedData.data;
        } catch (error) {
          console.error('Error parsing CSV:', error);
        }
      }
    })
  })
});

export const {
  useGetUserInfoQuery,
  useGetUserInfoByIdQuery,
  useUpdateUserMutation,
  useUploadUserProfileImageMutation,
  useUpdateUserRoleMutation,
  useAddProjectExperienceMutation,
  useDeleteProjectExperienceMutation,
  useGetAllProjectExperienceByUserQuery,
  useUpdateProjectExperienceMutation,
  useProfileVisibilityMutation,
  useGetProfileVisibilityStatusQuery,
  useLazyCheckUserOwnerShipInInitiativeQuery,
  useUpdateUserDetailsMutation,
  useLazyExportedUserQuery
} = userAPI;
