/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { IconMoodSad } from '@tabler/icons-react';
import InsightBox from 'components/Accessiblity/InsightBox';
import withRouter from 'hocs/withRouter';
import { Component } from 'react';
import { colors } from 'utils/constants/colors';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <InsightBox
          onButtonClick={() => {
            this.props.navigate('/');
            this.setState({ hasError: false });
          }}
          title="Something went wrong!"
          description="We are working on this, please try again!"
          renderElement={<IconMoodSad size={400} style={{ color: colors.default }} />}
          redirectButtonText={'Back to Home'}
        />
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
