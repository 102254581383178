import React from 'react';
import Locales from '@utils/Locales';
import Routes from '@routes/index';
import ErrorBoundary from '@components/ErrorBoundary';
import ReactHotToaster from '@components/Toast/ReactHotToaster';
import ThemeCustomization from '@themes/index';

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeCustomization>
        <Locales>
          <ReactHotToaster />
          <Routes />
        </Locales>
      </ThemeCustomization>
    </ErrorBoundary>
  );
};

export default App;
